import logo from "./logo.webp";
import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { FaRegUser, FaUserAlt } from "react-icons/fa";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { useAuthContext } from "../context/AuthContext";
import { removeToken } from "../helpers";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { NavHashLink } from "react-router-hash-link";

export default function Navbar() {
  const cart = useSelector((state) => state.persistedReducer.cart);
  // <li>
  //   <link to="/profil" onclick={()=>setisnavexpanded(false)}>
  //     <fauseralt size={25} />
  //     {`${user.username}`}
  //   </link>
  // </li>
  //             <li>
  //                 <Link to="/login" onClick={() => setIsNavExpanded(false)}>
  //                   <FaRegUser size={25} />
  //                 </Link>
  //               </li>
  const getTotalQuantity = () => {
    let total = 0;
    cart.forEach((item) => {
      total += item.quantity;
    });
    return total;
  };

  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [small, setSmall] = useState(false);

  const menu = useRef(null);

  const { user } = useAuthContext();
  const navigate = useNavigate();

  const handleLogout = () => {
    removeToken();
    navigate("/signin", { replace: true });
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () =>
        setSmall(window.pageYOffset > 150)
      );
    }
    const closeOpenMenus = (e) => {
      if (menu.current && isNavExpanded && !menu.current.contains(e.target)) {
        setIsNavExpanded(false);
      }
    };
    document.addEventListener("mousedown", closeOpenMenus);
    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  }, [isNavExpanded]);

  return (
    <>
      <header className={small ? "small" : ""} ref={menu}>
        <Link to="/">
          {/* <img src={logo} alt=""></img> */}
	<h3>matci carnica</h3>
        </Link>
        <div
          className="hamburger"
          onClick={() => {
            setIsNavExpanded(!isNavExpanded);
          }}
        >
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <nav
          className={`${isNavExpanded ? "active" : ""} ${
            small ? "smallUl" : ""
          }`}
        >
          <ul>
            <li>
              <NavHashLink
                to={"/#body__section__1"}
                smooth
                onClick={() => setIsNavExpanded(false)}
              >
                  Produse
              </NavHashLink>
            </li>
            <li>
              <Link to="/contact" onClick={() => setIsNavExpanded(false)}>
                  Contact
              </Link>
            </li>

            <li>
              <Link to="/despre_noi" onClick={() => setIsNavExpanded(false)}>
                  Despre noi
              </Link>
            </li>
            <li>
              <Link to="/blog/despre-rasa-carnica" onClick={() => setIsNavExpanded(false)}>
                  Despre Carnica
              </Link>
            </li>
            <li>
              <Link to="/blog" onClick={() => setIsNavExpanded(false)}>
                  Blog
              </Link>
            </li>
          </ul>
          <ul className="icons">
            {user ? (
              <>
                <li>
                  <Link to="/cart" onClick={() => setIsNavExpanded(false)}>
                    <AiOutlineShoppingCart size={30} />
                    <span
                      className={`${
                        getTotalQuantity() ? "cartCount" : "cartCount"
                      }`}
                    >
                      {getTotalQuantity() || ""}
                    </span>
                  </Link>
                </li>
              </>
            ) : (
              <div className="icons">
                <li>
                  <Link to="/cart" onClick={() => setIsNavExpanded(false)}>
                    <AiOutlineShoppingCart size={30} />
                    <span
                      className={`${
                        getTotalQuantity() ? "cartCount" : "cartCount"
                      }`}
                    >
                      {getTotalQuantity() || ""}
                    </span>
                  </Link>
                </li>
              </div>
            )}
          </ul>
        </nav>
      </header>
    </>
  );
}
