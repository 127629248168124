import axios from "axios";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export default function Contact() {
  const navigate = useNavigate();
  const onSubmit = (data) => {
    const { nume, telefon, mesaj } = data;
    axios
      .post("https://api.matcicarnica.ro/api/feedbacks", {
        data: {
          Nume: nume,
          telefon: telefon,
          Mesaj: mesaj,
        },
      })
      .then(navigate("/feedback", { replace: true }));
  };
  const {
    control,
    handleSubmit,
    register,
    formState,
    formState: { errors },
  } = useForm();
  const [value, setValue] = useState();
  useEffect(() => {
    window.scroll({ top: 0, behavior: "instant" });
  }, []);

  return (
    <div class="container mt-[10rem] mx-auto flex flex-col">
      <section class="mb-32 text-gray-800">
        <div class="flex flex-wrap">
          <div class="grow-0 shrink-0 basis-auto mb-6 md:mb-0 w-full md:w-6/12 px-3 lg:px-6">
            <h4 class="text-4xl  mb-10 text-left">Contactează-ne</h4>
            <p class="text-gray-500 text-2xl mb-6">
              Pentru întrebări, comenzi sau alte detalii, nu ezitați să ne
              contactați
            </p>
            <p class="text-gray-500 mb-2 text-xl">0763 262 641</p>
          </div>
          <div class="grow-0 shrink-0 basis-auto mb-12 md:mb-0 w-full md:w-6/12 px-3 lg:px-6">
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                name="nume"
                className="mt-3"
                placeholder="Nume"
                {...register("nume", {
                  required: true,
                })}
              />
              <input
                name="telefon"
                className="mt-3"
                placeholder="Numar de telefon"
                {...register("telefon", {
                  required: "Telefon is required",
                  pattern: {
                    value:
                      /^(\+4|)?(07[2-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\s|\.|\-)?([0-9]{3}(\s|\.|\-|)){2}$/gim,
                    message: "Va rugam introduceti un nr de tel ca lumea",
                  },
                })}
              />
              {formState.errors.telefon?.message && (
                <p className="text-red-600">
                  Va rugam sa introduceti un numar de telefon valid
                </p>
              )}
              <textarea
                {...register("mesaj", { required: true })}
                rows="5"
                name="mesaj"
                placeholder="Mesaj"
                className="mt-3 mb-3"
              ></textarea>
              <button
                type="submit"
                className="m-0 w-full px-[1.8rem] py-3 text-sm font-bold text-[#482627] bg-[#FEA21D]"
              >
                TRIMITE FEEDBACK
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}
